
import { Options, Vue } from "vue-class-component";
import Page from "@/components/Page.vue";
import { Constants } from "@/constants/constants";

@Options({
  components: {
    Page,
  },
  data: () => ({
    /**
     * The page type id
     */
    pageTypeId: Constants.StudentHomePageTypeId,
  }),
  computed: {
    /**
     * The current user
     */
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    /**
     * Redirects an employee to the employee start page when they log in
     */
    goToEmployeeHome(): void {
      if (
        this.currentUser !== undefined &&
        this.currentUser.entitlements.includes(
          "Employee",
          "or",
          "Tuition fees administratör"
        )
      ) {
        this.$router.push({
          name: "EmployeeHome",
        });
      }
    },
  },
  watch: {
    currentUser() {
      this.goToEmployeeHome();
    },
  },
  async mounted() {
    this.$refs.page.getPageSections();
  },
})
export default class StudentHome extends Vue {}
