import { PageSection } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class PageService extends BaseService {
  /**
   * Fetches the page sections with the specified page type id
   *
   * @param {number} pageTypeId - The id of the page type
   * @returns {Promise<Array<PageSection>>} A promise with the PageSection
   */
  getPageSectionsByTypeId(pageTypeId: number): Promise<Array<PageSection>> {
    const endpointController = "Page";
    const parameterName = "pageTypeId";
    const parameterArgument = pageTypeId;
    const getPageSectionsByTypeId = `${this.baseApiUrl}api/${endpointController}?${parameterName}=${parameterArgument}`;
    return this.fetch<Array<PageSection>>(getPageSectionsByTypeId);
  }
}

export default new PageService();
