
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import LuLeftMenu from "@/components/Lu/LuLeftMenu.vue";
import LuLeftMenuItem from "@/components/Lu/LuLeftMenuItem.vue";
import PageSectionComponent from "@/components/PageSectionComponent.vue";
import LuSpinner from "@/components/Lu/LuSpinner.vue";
import { PageSection } from "@/interfaces/api";
import type { IMenuItem } from "@/interfaces/IMenuItem";
import PageService from "@/services/page-service";

@Options({
  components: {
    LuLeftMenu,
    LuLeftMenuItem,
    LuSpinner,
    PageSectionComponent,
  },
  props: {
    /**
     * The page type id
     */
    pageTypeId: {
      type: Number as PropType<number>,
      required: false,
    },
  },
  data: () => ({
    /**
     * The page sections to be displayed
     */
    sections: new Array<PageSection>(),
    /**
     * Used to show or hide spinner
     */
    isLoading: false,
  }),
  computed: {
    /**
     * The currently chosen locale
     */
    locale() {
      return this.$i18n.locale;
    },
    /**
     * The current user
     */
    currentUser() {
      return this.$store.state.currentUser;
    },
    /**
     * The left menu items
     */
    leftMenuItems() {
      return this.generateLeftMenuItems();
    },
  },
  methods: {
    /**
     * Fetches the page sections
     */
    getPageSections(): void {
      this.isLoading = true;
      PageService.getPageSectionsByTypeId(this.pageTypeId)
        .then((data: Array<PageSection>) => {
          this.sections = data;
          this.isLoading = false;
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },
    /**
     * Generates the left menu items
     */
    generateLeftMenuItems(): IMenuItem[] | null {
      const menu = new Array<IMenuItem>();

      this.sections.forEach((section: PageSection) => {
        const menuTitle = this.menuTitle(section);
        if (menuTitle) {
          menu.push({
            id: section.id.toString(),
            text: menuTitle,
            url: `#${section.id}`,
          });
        }
      });

      return menu.length > 0 ? menu : null;
    },
    /**
     * Gets page section title depending on chosen locale
     */
    sectionTitle(section: PageSection): string {
      return this.locale === "sv" ? section.titleSv : section.titleEn;
    },
    /**
     * Gets page section content depending on chosen locale
     */
    sectionContent(section: PageSection): string {
      return this.locale === "sv" ? section.contentSv : section.contentEn;
    },
    /**
     * Gets page section link text depending on chosen locale
     */
    sectionLinkText(section: PageSection): string {
      return this.locale === "sv" ? section.linkTextSv : section.linkTextEn;
    },
    /**
     * Gets left menu title depending on chosen locale
     */
    menuTitle(section: PageSection): string {
      return this.locale === "sv" ? section.menuTitleSv : section.menuTitleEn;
    },
    /**
     * Redirects an employee to the employee start page when they log in
     */
    goToEmployeeHome(): void {
      if (
        this.currentUser !== undefined &&
        this.currentUser.entitlements.includes("Employee")
      ) {
        this.$router.push({
          name: "EmployeeHome",
        });
      }
    },
  },
})
export default class Page extends Vue {}
