
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import SessionStorageService from "@/services/sessionstorage-service";
import { Constants } from "@/constants/constants";

@Options({
  props: {
    /**
     * The heading for the section
     */
    heading: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The text content for the section
     */
    content: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The anchor for the section
     */
    anchor: {
      type: String as PropType<string>,
      required: true,
    },
    /**
     * The link text for the section
     */
    linkText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The external link url for the section
     */
    externalLinkUrl: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The router link url for the section
     */
    routerLinkUrl: {
      type: String as PropType<string>,
      required: false,
    },
    showSeparator: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },

  methods: {
    saveLinkUrl() {
      SessionStorageService.setItem(Constants.EntryUrl, this.routerLinkUrl);
    },
  },
})
export default class PageSectionComponent extends Vue {}
